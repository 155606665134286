<template>
  <q-layout view="hHr lpR fFr">
    <q-header class="bg-dark">
      <q-toolbar>
        <nuxt-link
          to="/"
          class="flex q-ml-sm"
        >
          <img
            src="/logos/kygunco.svg"
            width="142"
            height="50"
          >
        </nuxt-link>

        <q-space />

        <q-btn
          to="/account/orders"
          class="text-weight-bold"
          label="Orders"
          stretch
          flat
        />
      </q-toolbar>
    </q-header>

    <q-page-container>
      <slot />
    </q-page-container>
  </q-layout>
</template>

<script lang="ts" setup>
const quoteStore = useQuoteStore();
const checkoutStore = useCheckoutStore();

onUnmounted(() => {
  quoteStore.reset();
  checkoutStore.reset();
});
</script>
